<template>
  <v-dialog :value="value" persistent max-width="360">
    <v-card>
      <v-card-title class="grey lighten-4">
        <app-id-icon />
        <span class="body-1">
          {{ $t("time.calendar.shift_loading") }}
        </span>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-progress-linear indeterminate color="primary" height="10" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "Loading",
    props: {
      value: Boolean
    }
  };
</script>
